import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
import { EventService } from 'src/app/services/event.service';
import { ExperienceService } from 'src/app/services/experience.service';
declare var $: any;

@Component({
  selector: 'app-experience-edit',
  templateUrl: './experience-edit.component.html',
  styleUrls: ['./experience-edit.component.css'],
})
export class ExperienceEditComponent {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  token: any;
  roleList: any;
  editsubadminForm!: FormGroup;
  submitted = false;
  menuArr: any = [];
  menuErr = true;
  menuArr1: any = [];
  id: any;
  subadminData: any;
  roleData: any;
  getRoleVal: any = [];
  image: any;
  imageSrc: any;
  fileName!: string;
  blankImg: any = false;
  isLoading: boolean = false;

  constructor(
    public router: Router,
    private experienceService: ExperienceService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    private activateroute: ActivatedRoute,
    private datePipe: DatePipe,
    private prefrencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.geteditData();
    this.editsubadminForm = this.formBuilder.group({
      title: [
        '',
        [Validators.required, this.prefrencesService.noSpacesValidator],
      ],
      location: [
        '',
        [Validators.required, this.prefrencesService.noSpacesValidator],
      ],
      url: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(https?:\/\/)?([\w.]+\.\w{2,})(\/.*)?$/i),
        ],
      ],
      description: [
        '',
        [Validators.required, this.prefrencesService.noSpacesValidator],
      ],
      image: [''],
      isActive: [true, Validators.required],
      order: [1, [Validators.required, Validators.min(1)]],
    });
  }

  // it will be trigger when image uploaded
  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.image = file;
      reader.onload = (event: any) => {
        const image = new Image();
        this.imageSrc = event.target.result;
        this.fileName = file.name;
      };
    }
  }

  createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  // it returns form control
  get editSub() {
    return this.editsubadminForm.controls;
  }

  geteditData() {
    let obj = {
      id: this.id,
    };
    this.token = localStorage['token'];
    this.experienceService
      .experienceDetail(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.subadminData = res.data;
          this.editsubadminForm.patchValue({
            title: this.subadminData.title,
            location: this.subadminData.location,
            url: this.subadminData.url,
            description: this.subadminData.description,
            order: this.subadminData.order,
            isActive: this.subadminData.isActive,
          });
          const imageName = this.subadminData.image.split('/');
          this.fileName = imageName[imageName?.length - 1];
          this.image = this.subadminData.image;
          this.editsubadminForm.value.image = this.image;
          if (this.image) {
            this.imageSrc = this.image;
          }
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;
    if (this.editsubadminForm.invalid) {
      this.blankImg = true;
      this.isLoading = false;
      return;
    }
    if (this.image === '') {
      alert('Please Upload Image');
    }
    this.token = localStorage['token'];
    var formData = new FormData();
    formData.append('title', this.editsubadminForm.get('title')?.value);
    formData.append('location', this.editsubadminForm.get('location')?.value);
    formData.append('dateTime', this.editsubadminForm.get('dateTime')?.value);
    formData.append('url', this.editsubadminForm.get('url')?.value);
    formData.append('isActive', this.editsubadminForm.get('isActive')?.value);
    formData.append('order', this.editsubadminForm.get('order')?.value);
    formData.append(
      'description',
      this.editsubadminForm.get('description')?.value
    );
    formData.append('image', this.image);
    this.experienceService
      .experienceUpdate(this.id, formData, this.token)
      .subscribe({
        next: (res: any) => {
          if (res && res.status == 200) {
            this.toastr.success(res.message);
            this.router.navigate(['/experiences-list']);
            this.editsubadminForm.reset();
          } else {
            this.toastr.error(res?.message);
          }
        },
        error: (res) => {
          this.toastr.error(res.error.message);
        },
        complete: () => {
          this.isLoading = true;
        },
      });
  }

  resetFileInput() {
    // Reset the file input to clear the selected file
    this.fileInput!.nativeElement.value = '';
  }

  calculateAspectRatio(width: number, height: number): number {
    return width / height;
  }
}
