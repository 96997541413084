import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { MobileContentService } from 'src/app/services/mobile-content.service';

@Component({
  selector: 'app-content-add',
  templateUrl: './content-add.component.html',
  styleUrls: ['./content-add.component.css'],
})
export class ContentAddComponent {
  // ckeditor: any = '';
  // public Editor = ClassicEditor;
  token: any;
  content: any;
  privacyCont: any;
  sideStatus: any;
  isLoading: boolean = false;
  public editor: any = Editor;

  title: string = '';
  contentType: string = '';

  config = {
    toolbar: ['undo', 'redo', '|', 'link'],
    fontSize: {
      options: [9, 11, 13, 14, 17, 19, 21],
    },
    fontColor: {
      colors: [
        { color: '#ffffff', label: 'White' },
        { color: '#daa520', label: 'Golden' },
        { color: '#000000', label: 'Black' },
        { color: '#0000FF', label: 'Blue' },
      ],
    },
    language: 'es',
  };

  constructor(
    public router: Router,
    private mobileContentService: MobileContentService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.title = '';
    this.contentType = '';
    this.content = '';
  }
  cancelBtn() {
    this.ngOnInit();
  }

  onChange(event: any) {
    this.privacyCont = event.editor?.getData();
  }

  onSubmit() {
    this.token = localStorage['token'];
    if (!this.title) {
      this.toastr.warning('Title required');
      return;
    } else if (!this.contentType) {
      this.toastr.warning('contentType required');
      return;
    }
    var formData = new FormData();
    formData.append('title', this.title);
    formData.append('contentType', this.contentType);
    formData.append('content', this.privacyCont || '');
    this.mobileContentService
      .createMobileContent(formData, this.token)
      .subscribe({
        next: (res: any) => {
          if (res && res.status == 200) {
            this.toastr.success(res.message);
            this.router.navigate(['/mobile-content-list']);
          } else {
            this.toastr.success(res.message);
          }
        },
        error: (res) => {
          this.toastr.error(res.error.message);
        },
      });
  }
}
