import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ManualMatchesService } from 'src/app/services/manual-matches.service';

@Component({
  selector: 'app-manual-matches',
  templateUrl: './manual-matches.component.html',
  styleUrls: ['./manual-matches.component.css'],
})
export class ManualMatchesComponent {
  token: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  status: any;
  id: any = '';
  userList: any;
  search: any = '';
  userId: any = [];
  userOtherId: any = [];
  isChecked: any;
  isotherChecked: any;
  debounceTimer: any = null;

  constructor(
    public router: Router,
    private manualService: ManualMatchesService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.matchUserListing();
  }

  matchUserListing() {
    let offset = this.p - 1;
    if (this.search != '') {
      offset = 0;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.search,
    };
    this.token = localStorage['token'];
    this.manualService.matchUserList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.count = res.data.total_count;
        this.userList = res.data.userData;
      } else if (
        res.status == 400 &&
        res.message == 'Please provide valid token'
      ) {
        this.router.navigate(['/login']);
      } else {
        // this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  // checkFun(Id:any){
  //   return this.userId = Id
  //  }

  checkItem(event: any, item: any) {
    this.isChecked = event.target.checked;
    if (this.isChecked) {
      this.userId = item.id;
      //   this.userId=[]
      //   if (this.userId.indexOf(item.id) == -1) {
      //     this.userId = item.id;
      //   }
      // } else {
      //   let index = this.userId.indexOf(item.id);
      //   this.userId.splice(index, 1);
      // }
    }
  }
  checkOther(OtherId: any) {
    console.log(OtherId, 'OOOOOOO');
    return this.userOtherId.includes(OtherId);
  }

  checkOtherItem(event: any, itemm: any) {
    this.isotherChecked = event.target.checked;
    console.log(this.isotherChecked, 'ISSSSSS');
    if (this.isotherChecked) {
      if (this.userOtherId.indexOf(itemm.id) == -1) {
        this.userOtherId.push(itemm.id);
      }
      if (this.userOtherId.length >= 6) {
        alert('You can not select more then');
      }
    } else {
      let Otherindex = this.userOtherId.indexOf(itemm.id);
      this.userOtherId.splice(Otherindex, 1);
    }
  }

  onKeyUpEvent(event: any) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.search = event.target.value;
      this.matchUserListing();
    }, 500); // wait for 0.5seconds after user ends the writing
  }

  onSubmit() {
    this.token = localStorage['token'];
    let obj = {
      userId: this.userId,
      otherUserIds: this.userOtherId,
    };
    console.log(obj, 'OOOOOOOOo');
    this.manualService.matchAdd(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.toastr.success(res.message);
        this.router.navigate(['/manual-match']);
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.matchUserListing();
  }
}
