<div class="modal fade bd-example-modal-sm common_modal" [id]="modalId" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          {{ title }}
        </div>
        <button type="button" class="close" data-dismiss="modal">×</button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>