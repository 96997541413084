import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-approve-modal',
  templateUrl: './approve-modal.component.html',
  styleUrls: ['./approve-modal.component.css'],
})
export class ApproveModalComponent {
  @Input() id!: string;
  @Output() approveUser = new EventEmitter<string>();

  @Input() modalEvent: EventEmitter<'OPEN' | 'CLOSE'> = new EventEmitter();

  public subscription: Subscription | undefined;

  approve() {
    this.approveUser.emit('APPROVED');
  }

  ngOnInit(): void {
    this.subscribeToParentEmitter();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  subscribeToParentEmitter(): void {
    this.subscription = this.modalEvent.subscribe((event) => {
      if (event === 'OPEN') {
        $(`#${this.id}`).modal('show');
      } else {
        $(`#${this.id}`).modal('hide');
      }
    });
  }
}
