import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.css'],
})
export class RejectModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;

  @Input() modalEvent: EventEmitter<'OPEN' | 'CLOSE'> = new EventEmitter();

  @Output() rejectUser = new EventEmitter<{
    reason: string;
    action: 'UNAPPROVE' | 'REJECTED';
  }>();

  public subscription: Subscription | undefined;

  rejectionReasons: string[] = ['Socials', 'Profession'];
  selectedRejectionReason: string = this.rejectionReasons[0];
  customRejectionReason: string = '';

  ngOnInit(): void {
    this.subscribeToParentEmitter();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  clearForm() {
    this.selectedRejectionReason = this.rejectionReasons[0];
    this.customRejectionReason = '';
  }

  onRejectionOptionChange() {
    if (this.selectedRejectionReason !== 'Other') {
      this.customRejectionReason = '';
    }
  }

  reject() {
    const reason =
      this.selectedRejectionReason === 'Other'
        ? this.customRejectionReason
        : this.selectedRejectionReason;
    this.rejectUser.emit({ reason, action: 'REJECTED' });
  }

  subscribeToParentEmitter(): void {
    this.subscription = this.modalEvent.subscribe((event: 'OPEN' | 'CLOSE') => {
      if (event == 'OPEN') {
        this.clearForm();
        $(`#${this.id}`).modal('show');
      } else {
        $(`#${this.id}`).modal('hide');
      }
    });
  }
}
