<!-- Reject modal -->
<app-modal [modalId]="id" title="Reject User">
    <div class="filtercard">
        <p> Are you sure you want to Reject this user?</p>
        <div class="form-container">
            <label for="options" class="form-label">Reason:</label>
            <select id="options" class="form-select" [(ngModel)]="selectedRejectionReason"
                (change)="onRejectionOptionChange()">
                <option *ngFor="let option of rejectionReasons" [value]="option">{{ option }}</option>
                <option value="Other">Other</option>
            </select>
        </div>

        <div *ngIf="selectedRejectionReason === 'Other'" class="input-container">
            <input id="otherInput" type="text" class="form-input" [(ngModel)]="customRejectionReason"
                placeholder="Enter reason" />
        </div>

        <div class="common_btn">
            <button id="btn-cancel-reject" data-dismiss="modal">Cancel</button>
            <button class="btn-submit" (click)="reject()"
                [disabled]="selectedRejectionReason === 'Other' && !customRejectionReason">Yes</button>
        </div>
    </div>
</app-modal>