import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-unapprove-modal',
  templateUrl: './unapprove-modal.component.html',
  styleUrls: ['./unapprove-modal.component.css'],
})
export class UnapproveModalComponent {
  @Input() id!: string;
  @Output() unapproveUser = new EventEmitter<{
    action: 'UNAPPROVE' | 'REJECTED';
  }>();

  @Input() modalEvent: EventEmitter<'OPEN' | 'CLOSE'> = new EventEmitter();

  public subscription: Subscription | undefined;

  unApprove() {
    this.unapproveUser.emit({ action: 'UNAPPROVE' });
  }

  ngOnInit(): void {
    this.subscribeToParentEmitter();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  subscribeToParentEmitter(): void {
    this.subscription = this.modalEvent.subscribe((event) => {
      if (event === 'OPEN') {
        $(`#${this.id}`).modal('show');
      } else {
        $(`#${this.id}`).modal('hide');
      }
    });
  }
}
