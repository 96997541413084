<div class="middle-content-wrap">
    <div class="globalheading dflex dflex_bet">
        <h2>
            <span routerLink="/mobile-content-list" class="breadcumbs">Static Content</span> <span
                class="navigation-arrow">></span>
            <span>Edit Mobile Content</span>
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="common_btn">
                    <button class="export-btn" [routerLink]="['/mobile-content-list']"
                        [disabled]="isLoading">Cancel</button>
                    <button (click)="onSubmit()" [disabled]="isLoading">Save</button>
                </div>
            </div>
        </div>
    </div>
    <div class="staticcontent-wrap">
        <div class="content-main-div">
            <!-- Title Input -->
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" id="title" [(ngModel)]="title" class="form-control" placeholder="Enter Title">
            </div>

            <!-- Content Type Input -->
            <div class="form-group">
                <label for="contentType">Content Type</label>
                <input type="text" id="contentType" [(ngModel)]="contentType" class="form-control"
                    placeholder="Enter Content Type">
            </div>

            <div class="form-group">
                <label for="content">Content</label>
                <ckeditor class="mobileContent" [config]="config" [editor]="Editor" data="{{this.content}}"
                    (change)="onChange($event)">
                </ckeditor>
            </div>
        </div>
    </div>
</div>