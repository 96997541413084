import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MobileContentService {
  constructor(public http: HttpClient) {}
  baseUrl = environment.baseUrl;

  // Get list of mobile content
  getMobileContentList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.get(
      this.baseUrl +
        `/getMobileContentList?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}`,
      httpOptions
    );
  }

  // Create new mobile content
  createMobileContent(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.post(
      this.baseUrl + `/createMobileContent`,
      obj,
      httpOptions
    );
  }

  // Delete mobile content
  deleteMobileContent(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.delete(
      this.baseUrl + `/deleteMobileContent/${obj.id}`,
      httpOptions
    );
  }

  // Get details of a specific mobile content
  getMobileContentDetail(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.get(
      this.baseUrl + `/getMobileContentDetail?id=${obj.id}`,
      httpOptions
    );
  }

  // Update mobile content
  updateMobileContent(id: number, obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.patch(
      this.baseUrl + `/updateMobileContent/${id}`,
      obj,
      httpOptions
    );
  }
}
