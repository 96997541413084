import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerListDataService {
  // state management of users
  private userList: any[] = [];
  private count: number = 0;

  constructor(private customerService: CustomerService) {}

  getCustomerList(obj: any, token: string): Observable<any> {
    return this.customerService.getCustomerList(obj, token).pipe(
      map((res: any) => {
        if (res && res.status === 200) {
          this.count = res.data.total_count;
          this.userList = res.data.data.map((user: any) => {
            user.registrationDate = moment(user.registrationDate)
              .tz('Asia/Dubai')
              .format('YYYY-MM-DD HH:mm:ss');
            return user;
          });
        }
        return res;
      })
    );
  }

  getUserList(): any[] {
    return this.userList;
  }

  getCount(): number {
    return this.count;
  }
}
