import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { StaticContentService } from 'src/app/services/static-content.service';
import * as Editor from 'ckeditor5-custom-build/build/ckeditor';
import { MobileContentService } from 'src/app/services/mobile-content.service';

@Component({
  selector: 'app-content-edit',
  templateUrl: './content-edit.component.html',
  styleUrls: ['./content-edit.component.css'],
})
export class ContentEditComponent {
  // ckeditor: any = '';
  // public Editor = ClassicEditor;
  token: any;
  content: any;
  privacyCont: any;
  sideStatus: any;
  id: any;
  isLoading: boolean = false;
  public Editor: any = Editor;

  title: string = '';
  contentType: string = '';

  config = {
    toolbar: ['undo', 'redo', '|', 'link'],
    fontSize: {
      options: [9, 11, 13, 14, 17, 19, 21],
    },
    fontColor: {
      colors: [
        { color: '#ffffff', label: 'White' },
        { color: '#daa520', label: 'Golden' },
        { color: '#000000', label: 'Black' },
        { color: '#0000FF', label: 'Blue' },
      ],
    },
    language: 'es',
  };

  constructor(
    public router: Router,
    private mobileContentService: MobileContentService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.getContent();
  }
  cancelBtn() {
    this.ngOnInit();
  }
  getContent() {
    this.token = localStorage['token'];
    const obj = {
      id: this.id,
    };
    this.mobileContentService
      .getMobileContentDetail(obj, this.token)
      .subscribe({
        next: (res: any) => {
          if (res && res.status == 200) {
            let data = res.data;
            this.title = data.title;
            this.contentType = data.contentType;
            this.content = data.content;
          } else if (
            res.status == 400 &&
            res.message == 'Please provide valid token'
          ) {
            this.toastr.error(res.message);
            this.router.navigate(['/login']);
          } else {
            this.toastr.success(res.message);
          }
        },
        error: (res) => {
          this.toastr.error(res.error.message);
          this.router.navigate(['/mobile-content-list']);
        },
      });
  }

  onChange(event: any) {
    this.privacyCont = event.editor.getData();
  }

  onSubmit() {
    this.token = localStorage['token'];
    if (!this.title) {
      this.toastr.warning('Title required');
      return;
    } else if (!this.contentType) {
      this.toastr.warning('contentType required');
      return;
    }

    var formData = new FormData();
    formData.append('title', this.title);
    formData.append('contentType', this.contentType);
    formData.append('content', this.privacyCont || '');

    this.mobileContentService
      .updateMobileContent(this.id, formData, this.token)
      .subscribe({
        next: (res: any) => {
          if (res && res.status == 200) {
            this.toastr.success(res.message);
            this.router.navigate(['/mobile-content-list']);
          } else {
            this.toastr.success(res.message);
            alert(res.message);
          }
        },
        error: (res) => {
          this.toastr.error(res.error.message);
        },
      });
  }
}
