import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/services/event.service';
import * as moment from 'moment';
import { MobileContentService } from 'src/app/services/mobile-content.service';
declare var $: any;

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css'],
})
export class ContentListComponent {
  p: any = 1;
  count: any = 0;
  total: any = 10;
  token: any;
  subadminData: any;
  id: any;
  search: any = '';
  isChecked: any;
  subAdminId: any = [];
  masterSelected: boolean = false;

  constructor(
    public router: Router,
    private mobileContentService: MobileContentService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.contentListing();
  }

  contentListing() {
    let offset = this.p - 1;
    if (this.search != '') {
      offset = 0;
    } else {
      offset = this.p - 1;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.search,
    };

    this.token = localStorage['token'];
    this.mobileContentService
      .getMobileContentList(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.count = res.data.total_count;
          this.subadminData = res.data.data;
        } else if (
          res.status == 400 &&
          res.message == 'Please provide valid token'
        ) {
          this.toastr.error(res.message);
          this.router.navigate(['/login']);
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  onKeyUpEvent(event: any) {
    this.search = event.target.value;
    this.p = 1;
    this.contentListing();
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.contentListing();
    // this.ngOnInit();
  }

  openModal(item: any) {
    this.id = item.id;
  }

  deletePop() {
    let obj = {
      id: this.id,
    };
    this.mobileContentService
      .deleteMobileContent(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 400) {
          this.toastr.success(res.message);
        }
        this.contentListing();
      });
  }
}
