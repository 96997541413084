import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';
declare var $: any;
import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage.service';
import { CustomerListDataService } from 'src/app/services/customer-list-data.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
})
export class CustomerListComponent implements OnInit {
  constructor(
    public router: Router,
    private customerDataService: CustomerListDataService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private toastr: ToastrService
  ) {
    this.router.getCurrentNavigation()?.extras;
  }

  moment(arg0: any) {
    throw new Error('Method not implemented.');
  }

  token: any;
  userList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  status: any;
  id: any = '';
  search: any = '';
  fromdate: any = '';
  todate: any = '';
  userId: any = [];
  notexist: any;
  submitted: any;
  err: any;
  exportList: any;
  isUserStatus: string = this.storageService.getItem('isUserStatus')
    ? this.storageService.getItem('isUserStatus')
    : 'approved';
  registerTime: any = [];
  accessVal: any = false;
  limit: any = 10;
  offset: any;
  userType: String = 'Approved';
  blob: any;
  rejectModalId: string = 'rejectUserListModal';
  approveModalId: string = 'approveUserDetailModal';
  unApproveModalId: string = 'unApproveUserListModal';
  rejectEmitter = new EventEmitter<'OPEN' | 'CLOSE'>();
  unApproveEmitter = new EventEmitter<'OPEN' | 'CLOSE'>();
  approveEmitter = new EventEmitter<'OPEN' | 'CLOSE'>();
  debounceTimer: any = null;

  ngOnInit(): void {
    const storageOffset = this.storageService.getItem('offset');
    this.p = storageOffset ? storageOffset + 1 : 1;
    this.userListing();
  }

  userListing(pageButton?: boolean) {
    this.offset = this.p - 1;
    // if (this.search != "") {
    //   this.offset = 0;
    // }
    if (pageButton) {
      this.storageService.setItem('offset', this.offset);
    }
    let obj = {
      limit: this.total,
      offset: this.offset,
      fromdate: this.fromdate,
      todate: this.todate,
      search: this.search,
      action: this.isUserStatus,
    };
    this.token = localStorage['token'];
    this.userList = [];
    this.customerDataService
      .getCustomerList(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status === 200) {
          this.count = this.customerDataService.getCount();
          this.userList = this.customerDataService.getUserList();
        } else if (
          res.status == 400 &&
          res.message == 'Please provide valid token'
        ) {
          this.toastr.error(res.message);
          this.router.navigate(['/login']);
          this.notexist = res.message;
          this.submitted = true;
          this.err = res.data;
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.userListing(true);
  }

  onKeyUpEvent(event: any) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.search = event.target.value;
      this.userListing();
    }, 500); // wait for 0.5seconds after user ends the writing
  }

  onExport(name: any) {
    if (confirm('Are you sure you want to export these Customers?')) {
      let obj = {
        search: this.search,
        user_type: name,
      };
      this.token = localStorage['token'];
      this.customerService.exportCustomer(obj).subscribe((res: any) => {
        this.blob = window.URL.createObjectURL(
          new Blob([res], { type: 'application/xlsx' })
        );
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.setAttribute('download', 'Export Report.xlsx');
        // link.download = "help.pdf";
        link.click();
        // if (res.status == 200) {
        //   this.exportList = res;
        //   window.open(this.exportList, "_self");
        //   this.toastr.success(res.message);
        // } else if (res.status == 201) {
        //   this.toastr.success(res.message);
        // }
      });
    }
  }

  actionKey!: string;
  openModal(id: Number, action: string) {
    this.id = id;
    this.actionKey = action;
    $('#blockUnblockUser').show();
  }

  blockUnblockUser: any;
  blockUnblockDeleteAction() {
    let obj = {
      id: this.id,
      action: this.actionKey,
    };
    this.token = localStorage['token'];
    let x = document.getElementById('closeM');
    x!.setAttribute('data-dismiss', 'modal');
    this.customerService.blockUnblock(obj, this.token).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        $('#blockUnblockUser').hide();
        this.userListing();
      }
      if (res.status == 400) {
        this.toastr.info(res.message);
        this.userListing();
      }
    });
  }

  // reject modal
  openRejectModal(id: Number) {
    this.id = id;
    this.rejectEmitter.emit('OPEN');
    console.log('reject modal');
  }

  // move back to waitlist modal
  openUnapproveModal(id: Number) {
    this.id = id;
    this.unApproveEmitter.emit('OPEN');
  }

  updateUserApproval(event: {
    action: 'UNAPPROVE' | 'REJECTED';
    reason?: string;
  }) {
    const { reason, action } = event;
    let obj: any = {
      id: this.id,
      action,
    };

    if (reason) {
      obj.reason = reason;
    }

    this.customerService
      .updateUserApproval(obj, this.token)
      .subscribe((res: any) => {
        if (res.status === 200) {
          this.toastr.success(res.message);

          // Hide modals based on the action
          if (action === 'REJECTED') {
            this.rejectEmitter.emit('CLOSE');
          } else if (action === 'UNAPPROVE') {
            this.unApproveEmitter.emit('CLOSE');
          }

          this.userListing();
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  openApprovedUnapprovedUserModal(id: any) {
    this.id = id;
    this.approveEmitter.emit('OPEN');
  }

  getAccess(event: any) {
    this.accessVal = event.target.checked;
    console.log(this.accessVal, 'Access');
  }

  approveUnapprovedf() {
    let obj = {
      id: this.id,
      lifetime_access: this.accessVal,
    };
    this.token = localStorage['token'];
    this.customerService
      .approvedUnapproved(obj, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          this.approveEmitter.emit('CLOSE');
          this.userListing();
        }
      });
  }

  isButtonActive(buttonStatus: any) {
    this.storageService.setItem('isUserStatus', buttonStatus);
    this.isUserStatus = buttonStatus;
    this.total = 10;
    this.p = 1;
    this.offset = 0;
    this.storageService.setItem('offset', this.offset);
    this.userListing();
  }
}
