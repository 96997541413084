<app-header></app-header>
<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
  <!-- Dashboard HTML Start Here  -->
  <div class="globalheading dflex dflex_bet">
    <h2>
      <span routerLink="/customers-list" class="breadcumbs">Customers</span> <span class="navigation-arrow">></span>
      <span>{{status.toUpperCase()}} Customer
        Details</span>
    </h2>
  </div>
  <div class="rejectionReason" *ngIf="status=='rejected'">
    Rejection Reason :
    <div class="activebtn">
      <button type="button" class="btnActive"
        style="background-color: rgb(244, 62, 62); color: #faf6ed;">{{personalDetails?.rejection_reason ||
        'NotGiven'}}</button>
    </div>
  </div>
  <div class="customer-detial-view">
    <div style="display: flex; justify-content: space-between;padding:10px">
      <button (click)="moveToPrevUser()" [disabled]="!prev_user_id" style="cursor: pointer;"><i class="fa fa-arrow-left"
          aria-hidden="true"></i></button>
      <button (click)="moveToNextUser()" [disabled]=" !next_user_id" style="cursor: pointer;"><i
          class="fa fa-arrow-right" aria-hidden="true"></i></button>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="user-detail-card user-detail-view-card userdetail-view">
          <div class="user-img-wrap"
            *ngIf="profileStatus?.selfieImage == null  || profileStatus?.selfieImage == ''  ; else profile ">
            <img src="assets/images/userimg.png" />
          </div>
          <ng-template #profile>
            <div class="user-img-wrap">
              <img src="{{ profileStatus?.selfieImage}}" />
            </div>
          </ng-template>



          <div class="dflex dflex_bet">
            <div class="user-card-left">
              <!-- <span>&nbsp;Connect ID : {{ userInfo?.userProfileNo || 'NA' }}</span> -->

              <div class="d-flex username_div">
                <p>{{ personalDetails?.name || "N/A" }}</p>
                <div class="d-flex">
                  <div class="verifyed_user_text">
                    <button class="notverify" *ngIf="userInfo?.verifyStatus == ''">
                      Not Verified
                    </button>
                    <button class="notverify" *ngIf="userInfo?.verifyStatus == 'No'">
                      Not Verified
                    </button>
                    <button *ngIf="userInfo?.verifyStatus == 'Yes'">
                      Verified
                      <img src="assets/images/verified.svg" alt="">
                    </button>
                  </div>

                  <div class="premium_user_text">

                    <ng-template #premiumText>
                      <button>
                        Life Time Access /Monthly Access
                        <img src="assets/images/premium.svg" alt="">
                      </button>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="userextrainfo">
                <ul>
                  <li>
                    <img src="assets/images/gender-icon.svg" alt="" />
                    <span>{{ personalDetails?.gender || "N/A" }}</span>
                  </li>
                  <li>
                    <img src="assets/images/email-icon.svg" alt="" />
                    <span>{{ personalDetails?.email || "N/A" }}</span>
                  </li>
                  <li>
                    <img src="assets/images/contact-icon.svg" alt="" />
                    <span> {{ personalDetails?.phone || "N/A" }}</span>
                  </li>
                  <li>
                    <img src="assets/images/time.svg" alt="" />

                    <span>{{personalDetails?.registrationDate | date:'medium' }}</span>
                  </li>
                </ul>
              </div>



            </div>
            <div class="user-card-right d-flex ">
              <div class="statusshow">
                Status :
                <div class="activebtn">
                  <button type="button" class="btnActive" *ngIf="profileStatus?.status == 'Pending'"
                    style="background-color: #f3d07766; color: #df930a;">Pending</button>
                </div>
                <div class="activebtn">
                  <button type="button" class="btnActive" *ngIf="profileStatus?.status == 'Verified'"
                    style="background-color: #76f16c66; color: #02b708;">Verified</button>
                </div>
              </div>
              <div class="user-action-buttons">
                <div class="notification">
                  <button class="btn btn-dark" data-toggle="modal" data-target="#deletModal"> Send Notification</button>
                </div>
              </div>
              <div class="user-action-buttons" style="flex-basis: 100%;margin-top: 10px; gap:10px;justify-content: end;"
                *ngIf="status=='unapproved' && display_reject_approve_buttons">
                <button class="btn btn-dark" data-toggle="modal" (click)="openApproveModal()">Approve
                </button>
                <button class="btn btn-danger" data-toggle="modal" (click)="openRejectModal()">Reject</button>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="basic-detail-view">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="user-detail-card user-detail-view-card half-row">
                <span>Personal Details</span>
                <ul>
                  <li>
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Name:
                        {{ personalDetails?.name || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Email:
                        {{ personalDetails?.email || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><img src="assets/images/user-icon.svg" alt="" /><span>Gender:
                        {{ personalDetails?.gender || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Phone No.:
                        {{ personalDetails?.phone || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" *ngIf="personalDetails?.profileCreatedOn; else naText"><img
                        src="assets/images/about-icon.svg" alt="" /><span>Profile Created Date:
                        {{ personalDetails?.profileCreatedOn | date:'medium'}}</span></a>
                    <ng-template #naText>
                      <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Profile
                          Created
                          Date:
                          {{ personalDetails?.profileCreatedOn || 'N/A' }}</span></a>
                    </ng-template>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Profile
                        Complete:
                        {{ personalDetails?.profileCompleted || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Registration
                        Date:
                        {{ personalDetails?.registrationDate ? (personalDetails.registrationDate | date:'medium') :
                        'N/A' }}</span></a>
                  </li>
                  <li *ngIf="this.status == 'approved'">
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" /><span>Total Referral
                        :
                        {{ referralCount || 'N/A' }}</span></a>
                  </li>
                  <li *ngIf="this.status == 'approved'">
                    <a href="javascript:void(0);"><img src="assets/images/about-icon.svg" alt="" />
                      <div style="display: flex;">Marketing : <span *ngIf="this.marketStatus == 1; else showMarket">
                          Yes
                        </span>
                        <ng-template #showMarket>
                          No
                        </ng-template>

                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="user-detail-card user-detail-view-card half-row">
                <div class="d-flex align-items-center justify-content-between">
                  <span>Region, Group, Tier</span>
                  <button *ngIf="status=='approved'" class="anchortag" data-toggle="modal"
                    data-target="#editTierModal">Edit</button>
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0);"><i class="fa fa-flag"></i><span>Region:
                        {{ personalDetails?.region_name || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><i class="fa fa-city"></i><span>Group:
                        {{ personalDetails?.group_name || 'N/A' }}</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"><i class="fa fa-users"></i><span>Tier:
                        {{ personalDetails?.tier_name || 'N/A' }}</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="user-detail-card user-detail-view-card">
            <span>About Yourself</span>
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img class="alcoholcons" [src]="getImage(aboutYourself?.alcohol_consumption)"
                    alt="alcohol_consumption" />
                  <span>{{capitalizeFirstLetterOfEachWord('alcohol_consumption')}}:
                    {{formatData(aboutYourself?.alcohol_consumption)}}
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <img src="assets/images/height-icon.svg" alt="" />
                  <span>Height :
                    {{aboutYourself?.height?.[0]?.text || 'N/A'}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.fitness)" alt="fitness" />
                  <span>{{capitalizeFirstLetterOfEachWord('fitness')}}:
                    {{formatData(aboutYourself?.fitness)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <!-- <img [src]="getImage(aboutYourself?.hobbies)" alt="hobbies" /> -->
                  <img src="assets/images/about-icon.svg" alt="hobbies" />
                  <span>{{capitalizeFirstLetterOfEachWord('hobbies')}}:
                    {{formatData(aboutYourself?.hobbies)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.i_usually_wake_up_at)" alt="i_usually_wake_up_at" />
                  <span>{{capitalizeFirstLetterOfEachWord('i_usually_wake_up_at')}}:
                    {{formatData(aboutYourself?.i_usually_wake_up_at)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.interest)" alt="interest" />
                  <span>{{capitalizeFirstLetterOfEachWord('interest')}}:
                    {{formatData(aboutYourself?.interest)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.meet_vibes)" alt="meet_vibes" />
                  <span>{{capitalizeFirstLetterOfEachWord('meet_vibes')}}:
                    {{formatData(aboutYourself?.meet_vibes)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.personality)" alt="personality" />
                  <span>{{capitalizeFirstLetterOfEachWord('personality')}}:
                    {{formatData(aboutYourself?.personality)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.smoker)" alt="smoker" />
                  <span>{{capitalizeFirstLetterOfEachWord('smoker')}}:
                    {{formatData(aboutYourself?.smoker?.name)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.values)" alt="values" />
                  <span>{{capitalizeFirstLetterOfEachWord('values')}}:
                    {{formatData(aboutYourself?.values)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.you_are_on_maxion_for)" alt="you_are_on_maxion_for" />
                  <span>{{capitalizeFirstLetterOfEachWord('you_are_on_maxion_for')}}:
                    {{formatData(aboutYourself?.you_are_on_maxion_for)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.your_life_goals)" alt="your_life_goals" />
                  <span>{{capitalizeFirstLetterOfEachWord('your_life_goals')}}:
                    {{formatData(aboutYourself?.your_life_goals)}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.your_life_goals)" alt="your_life_goals" />
                  <span>{{capitalizeFirstLetterOfEachWord('gender_preference')}}:
                    {{aboutYourself?.gender_preference || 'N/A'}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.your_life_goals)" alt="your_life_goals" />
                  <span>{{capitalizeFirstLetterOfEachWord('connection_pitch')}}:
                    {{aboutYourself?.connection_pitch || 'N/A'}}
                  </span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0);">
                  <img [src]="getImage(aboutYourself?.your_life_goals)" alt="your_life_goals" />
                  <span>{{capitalizeFirstLetterOfEachWord('occupation')}}:
                    {{aboutYourself?.occupation || 'N/A'}}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="listingformat">
          <span>Uploads</span>
          <div class="tabledatawrap">
            <div class="user-images-wrap">
              <span>Photos</span>
              <div class="user-pic-wrap">
                <ul *ngIf="this.userImages?.length > 0">
                  <li *ngFor="let item of userImages">
                    <div class="userimage-upload">
                      <img (click)="imageData($event , item)" data-toggle="modal" data-target="#photoModal"
                        [src]="item?.profile_image" />
                    </div>
                  </li>
                  <li>
                    <div class="userimage-upload">
                      <img src="{{ profileStatus?.selfieImage}}" />
                    </div>
                  </li>
                </ul>

                <ul *ngIf="this.userImages?.length == 0">
                  <ul>
                    <li>
                      <div class="userimage-upload">
                        <img src="assets/images/Ellipse 24@2x.png" />
                      </div>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-7">
               
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="listingformat">
          <span>Social Details</span>
          <div class="tabledatawrap">
            <div class="form-group">
              <p>Name : <strong>{{socialDetails?.name}}</strong></p>
            </div>
            <div class="form-group">
              <p>Email : <strong>{{socialDetails?.email}}</strong></p>
            </div>
            <div class="form-group">
              <p>Social Type : <strong>{{socialDetails?.social_type}}</strong></p>
            </div>
            <div class="form-group">
              <p>Url : <a [href]="socialDetails?.url" target="_blank"><strong>{{socialDetails?.url}}</strong></a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="listingformat paymenthistory">
          <span>ID Verification Details</span>
          <div class="tabledatawrap">
            <div class="form-group">
              <p>Full Name : <strong>{{profileStatus?.fullName}}</strong></p>
            </div>
            <div class="form-group">
              <p>Nationality : <strong>{{profileStatus?.nationality}}</strong></p>
            </div>
            <div class="form-group">
              <p>Date Of Birth : <strong>{{profileStatus?.dateOfBirth |date:'shortDate'}}</strong></p>
            </div>
            <div class="form-group">
              <p>Date of Expiry : <strong>{{profileStatus?.dateOfExpiry |date:'shortDate'}}</strong></p>
            </div>
            <div class="form-group">
              <p>Status : <strong>{{profileStatus?.status }}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="listingformat paymenthistory">
          <span>Connection Preference</span>
          <div class="tabledatawrap table-responsive">
            <div class="form-group">
              <p>Connection Status : <select (change)="matchStatus($event)">
                  <option value="">All</option>
                  <option value="mutual">Mutual</option>
                  <option value="sent">Sent</option>
                  <option value="received">Received</option>
                  <option value="maybe">May be</option>
                  <option value="pass">Pass</option>
                  <option value="pending">Pending</option>
                </select></p>
            </div>
            <div class="form-group">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Connection Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataMeet">
                    <td>{{item?.date}}</td>
                    <td>{{item?.name}}</td>
                    <td>{{item?.email}}</td>
                    <td>{{item?.phone}}</td>
                    <td>{{item?.match_status}}</td>
                    <td>
                      <div class="" *ngIf="item.match_status =='Mutual';else dissablebtn" (click)="meetinfoData(item)">
                        <button type="button" class="btn dtlsbtn" data-toggle="modal" data-target="#prefernceModal">Meet
                          Detail</button>
                      </div>
                      <ng-template #dissablebtn>
                        <button type="button" class="btn dissablebtn" disabled>Meet Detail</button>
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Middle Content CSS End Here  -->

<!--Match Preference Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="prefernceModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Meet Details
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="listingformat">
          <div class="tabledatawrap table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Venue Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Meet Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of matchData">
                  <td>{{item?.venue_name}}</td>
                  <td>{{item?.date}}</td>
                  <td>{{item?.time}}</td>
                  <td>{{item?.meet_status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Match Preference Moda -->
<!-- Notification Popup -->
<div class="modal fade bd-example-modal-sm common_modal" id="deletModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Send Notification
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard" [formGroup]="addnotificationForm">
          <div class="form-group full-input-wrap">
            <p>
              <span class="your-name"><input name="your-name" placeholder="Title" value="" size="40"
                  class="form-control" type="text" autocomplete="off" autocomplete="off" formControlName="Title"
                  [ngClass]="{
                            'is-invalid': submitted && addf['Title'].errors
                          }" />
                <div class="errorDiv" *ngIf="submitted && addf['Title'].errors" class="invalid-feedback">
                  <div class="error" *ngIf="addf['Title'].errors['required']">
                    Title is required
                  </div>
                </div>
              </span>
            </p>
          </div>
          <div class="form-group full-input-wrap">
            <p>
              <span>
                <textarea class="form-control" autocomplete="off" placeholder="Body" name="" id="" autocomplete="off"
                  cols="30" rows="10" formControlName="Body" [ngClass]="{
                          'is-invalid': submitted && addf['Body'].errors
                        }"></textarea>
                <div class="errorDiv" *ngIf="submitted && addf['Body'].errors" class="invalid-feedback">
                  <div class="error" *ngIf="addf['Body'].errors['required']">
                    Body is required
                  </div>
                </div>
              </span>
            </p>
          </div>
          <div class="form-group">
            <button class="btn btn-block" (click)="onSubmit()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Notification Popup -->


<!-- Upload Photo Shown Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="photoModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Upload Image
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="uploadimge">
          <img src="{{this.uploadImg?.profile_image}}">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Upload Photo Shown Modal -->

<!-- Edit Tier Modal -->
<div class="modal fade bd-example-modal-sm common_modal" id="editTierModal" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog animated fadeInDown">
    <div class="modal-content">
      <div class="modal-header">
        <div class="popupheding">
          Edit Tier
        </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <div class="filtercard">
          <form>
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group full-input-wrap">
                    <p>
                      <label>Tier <span>*</span></label>
                      <span>
                        <select2 [data]="dataTiersList" [value]="tier" minCountForSearch="0" noResultMessage="No result"
                          (update)="updateTier($event)">
                        </select2>
                        <div class="errorDiv" *ngIf="submitted && !tier" class="invalid-feedback">
                          <div class="error">
                            Field is required
                          </div>
                        </div>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="common_btn">
              <button data-dismiss="modal">Back</button>
              <button id="close" (click)="onTierSubmit()">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Reject modal -->
<app-reject-modal [id]="rejectModalId" [modalEvent]="rejectEmitter"
  (rejectUser)="rejectUser($event)"></app-reject-modal>

<!-- Approve modal -->
<app-approve-modal [id]="approveModalId" [modalEvent]="approveEmitter"
  (approveUser)="approveUser()"></app-approve-modal>