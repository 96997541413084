import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/dashboard.service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('pieChart') pieChart: any;
  dynamicData: any[] | undefined;

  chartOptions = {
    title: {
      text: '',
    },
    data: [
      {
        type: 'column',
        dataPoints: [
          { label: 'Jan', y: 10 },
          { label: 'Feb', y: 15 },
          { label: 'Mar', y: 25 },
          { label: 'Apr', y: 30 },
          { label: 'May', y: 28 },
        ],
      },
    ],
  };

  filter: any = 'year';
  filterApp: any = 'year';
  filterPro: any = 'year';
  change: any = 'Approved';
  userTpe: any = 'Active';
  eveTpe: any = '';
  token: any;
  totalUsers: any;
  totalAppUsers: any;
  totalPro: any;
  totalEvents: any;
  pichartOptions: any = {};
  userPichartOptions: any = {};
  memberPiChartOptions: any = {};
  applicationPichartOptions: any = {};
  dashboardData: any = [];
  applicationDetailsArray: any = [];
  profileDetailsArray: any = [];
  memberShipDetailsArray: any = [];
  profilePiChartOptions: any = [];
  meetDetailsArray: any = [];
  interestDtlsArray: any = [];
  hobbiesDtlsArray: any = [];
  meetPiChartOptions: any = [];
  interestAnalyticOptions: any = [];
  iAmDtlsArray: any = [];
  hobbiesAnalyticOptions: any = [];
  personalityAnalyticOptions: any = [];
  preferenceOptions: any = [];
  perferenceDtlsArray: any = [];
  valuesArray: any = [];
  valueAnalyticOptions: any = [];
  lifeGoalArray: any = [];
  lifeGoalAnalyticOptions: any = [];
  maxionGoalArray: any = [];
  maxionGoalAnalyticOptions: any = [];
  vibesArray: any = [];
  vibeAnalyticOptions: any = [];
  vibeQuestionArray: any = [];
  questionAnalyticOptions: any = [];
  habitArray: any = [];
  habitAnalyticOptions: any = [];
  meetTotal: number = 0;
  FromDate: any = '';
  ToDate: any = '';
  FromDatee: any = '';
  FromDateP: any = '';
  ToDateP: any = '';
  ToDatee: any = '';
  maxDate: any;
  habitArr: any = {};
  constructor(
    public router: Router,
    private dashboardService: DashboardService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    var now = new Date();
    var toDate: any = now.getDate();
    if (toDate < 10) {
      toDate = '0' + toDate;
    }
    var month: any = now.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var year: any = now.getFullYear();
    let today = year + '-' + month + '-' + toDate;
    this.maxDate = today;

    this.totalUser();
    this.totalAppUser();
    this.totalProfile();
    this.totalEvent();
    this.createUserPieChart(); // initial call
    this.createApplicationPieChart(); // initial call
    this.createProfilePieChart(); // initial call
    this.createMembershipPieChart(); //  initial call
    this.dashMeetGraphData(); // initial call
    this.dashUserSectionDetails(); // application, member and user details
    this.applicationDetails();
    this.dashProfileSectionDetails();
    this.dashMembershipGraphData();
    this.dashInterestGraphData();
    this.dashHobbiesGraphData();
    this.dashpreferenceGraphData();
    this.dashIamSectionGraphData();
    this.dashValueGraphData();
    this.dashLifeGoalGraphData();
    this.dashMaxionGoalGraphData();
    this.dashVibeGraphData();
    this.dashVibeQuesGraphData();
    this.dashHabitGraphData();
  }

  fromDate(event: any) {
    this.FromDate = event.target.value;
    this.totalUser();
  }

  toDate(event: any) {
    this.ToDate = event.target.value;
    this.totalUser();
  }

  fromDatePro(event: any) {
    this.FromDateP = event.target.value;
    this.totalProfile();
  }

  toDatePro(event: any) {
    this.ToDateP = event.target.value;
    this.totalProfile();
  }

  fromDatee(event: any) {
    this.FromDatee = event.target.value;
    this.totalAppUser();
  }

  toDatee(event: any) {
    this.ToDatee = event.target.value;
    this.totalAppUser();
  }

  // Create pie chart for User
  createUserPieChart() {
    this.userPichartOptions = {
      animationEnabled: true,
      title: {
        text: '',
      },
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.dashboardData).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Create pie chart for Application
  createApplicationPieChart() {
    this.applicationPichartOptions = {
      animationEnabled: true,
      title: {
        text: '',
      },
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.applicationDetailsArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Create pie chart for Profile
  createProfilePieChart() {
    this.profilePiChartOptions = {
      animationEnabled: true,
      title: {
        text: '',
      },
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.profileDetailsArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // create membership graph
  createMembershipPieChart() {
    this.memberPiChartOptions = {
      animationEnabled: true,
      title: {
        text: '',
      },
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.memberShipDetailsArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // create meet graph
  createMeetPieChart() {
    this.meetPiChartOptions = {
      animationEnabled: true,
      title: {
        text: '',
        fontFamily: 'Impact',
        fontWeight: 'normal',
      },
      legend: {
        verticalAlign: 'top',
        horizontalAlign: 'centre',
      },
      data: [
        {
          type: 'doughnut',
          showInLegend: true,
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.meetDetailsArray).map(
            ([key, value]) => ({
              y: value,
              // name: key,
              legendText: key,
              indexLabel: key,
            })
          ),
        },
      ],
    };
  }

  // Interest Analytics
  createInterestChart() {
    this.interestAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.interestDtlsArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Hobbies Analytics Graph

  createHobbiesChart() {
    this.hobbiesAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.hobbiesDtlsArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Personality Analytics Graph

  personalitySectionChart() {
    this.personalityAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.iAmDtlsArray).map(([key, value]) => ({
            y: value,
            name: key,
          })),
        },
      ],
    };
  }

  // Values Analytics Graph

  valueSectionChart() {
    this.valueAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.valuesArray).map(([key, value]) => ({
            y: value,
            name: key,
          })),
        },
      ],
    };
  }

  // Life Goal Analytics Graph

  lifeGoalSectionChart() {
    this.lifeGoalAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.lifeGoalArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Maxion Goal Analytics Graph

  maxionGoalSectionChart() {
    this.maxionGoalAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.lifeGoalArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Vibe Analytics Graph

  vibeSectionChart() {
    this.vibeAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.vibesArray).map(([key, value]) => ({
            y: value,
            name: key,
          })),
        },
      ],
    };
  }

  // Question Vibe Analytics Graph

  vibeQuesSectionChart() {
    this.questionAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.vibeQuestionArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  // Habit Analytics Graph

  habitSectionChart() {
    this.habitAnalyticOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.habitArr).map(([key, value]) => ({
            y: value,
            name: key,
          })),
        },
      ],
    };
  }

  // Preference Count
  createpreferenceChart() {
    this.preferenceOptions = {
      title: {
        text: '',
      },
      animationEnabled: true,
      data: [
        {
          type: 'pie',
          startAngle: -90,
          indexLabel: '{name}: {y}',
          yValueFormatString: '#,###.##',
          dataPoints: Object.entries(this.perferenceDtlsArray).map(
            ([key, value]) => ({
              y: value,
              name: key,
            })
          ),
        },
      ],
    };
  }

  totUser(event: any) {
    this.filter = event.target.value;
    console.log(this.filter);
    this.totalUser();
    // this.totalAppUser();
    this.totalProfile();
  }

  totAppUser(event: any) {
    this.filterApp = event.target.value;
    this.totalAppUser();
  }

  totProUser(event: any) {
    this.filterPro = event.target.value;
    this.totalProfile();
  }

  userChange(event: any) {
    this.change = event.target.value;
    console.log(this.filter);
    this.totalAppUser();
  }

  userType(event: any) {
    this.userTpe = event.target.value;
    console.log(this.filter);
    this.totalAppUser();
    this.totalEvent();
  }

  eveType(event: any) {
    this.eveTpe = event.target.value;
    this.totalEvent();
  }

  totalUser() {
    let obj = {
      filter: this.filter,
      fromdate: this.FromDate,
      todate: this.ToDate,
    };
    //
    this.token = localStorage['token'];
    this.dashboardService.totalUser(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.totalUsers = res.data;
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  totalAppUser() {
    let obj = {
      filter: this.filterApp,
      type: this.change,
      fromdate: this.FromDatee,
      todate: this.ToDatee,
    };
    //
    this.token = localStorage['token'];
    this.dashboardService
      .totalapproveUser(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.totalAppUsers = res.data;
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  totalProfile() {
    let obj = {
      filter: this.filterPro,
      type: this.userTpe,
      fromdate: this.FromDateP,
      todate: this.ToDateP,
    };
    //
    this.token = localStorage['token'];
    this.dashboardService
      .totalUserProfile(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.totalPro = res.data;
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  totalEvent() {
    let obj = {
      type: this.eveTpe,
    };
    //
    this.token = localStorage['token'];
    this.dashboardService
      .totaldashEvents(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.totalEvents = res.data;
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  // dashboard user section details
  dashUserSectionDetails() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashUserSectionDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.dashboardData = res.data;
          this.createUserPieChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  // dashboard application details
  applicationDetails() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashApplicationDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.applicationDetailsArray = res.data;
          this.createApplicationPieChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  // dashboard profile details
  dashProfileSectionDetails() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashProfileDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.profileDetailsArray = res.data;
          this.createProfilePieChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // dashboard member details
  dashMembershipGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashMemberShipDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.memberShipDetailsArray = res.data;
          this.createMembershipPieChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // dashboard Meet details
  dashMeetGraphData() {
    this.token = localStorage['token'];
    this.dashboardService.dashMeetDetails(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.meetDetailsArray = res.data;

        // var meetTotal = 0;
        // this.meetDetailsArray.forEach((item:any) => {
        //     meetTotal += item;
        //     console.log(meetTotal , 'CHCHCHCCHHCH')
        // });
        this.createMeetPieChart(); // update the pie chart
      } else {
        this.toastr.success(res.message);
      }
    });
  }

  // Dashboard interest analytics
  dashInterestGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashInterestDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.interestDtlsArray = res.data;
          this.createInterestChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Hobbies analytics

  dashHobbiesGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashHobbiesDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.hobbiesDtlsArray = res.data;
          this.createHobbiesChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Personality Section analytics

  dashIamSectionGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashIamSectionDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.iAmDtlsArray = res.data;
          this.personalitySectionChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Values Chart

  dashValueGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashValuesDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.valuesArray = res.data;
          this.valueSectionChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Life Goals Chart

  dashLifeGoalGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashLifeGoalDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.lifeGoalArray = res.data;
          this.lifeGoalSectionChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Maxion Goals Chart

  dashMaxionGoalGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashMaxionGoalDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.maxionGoalArray = res.data;
          this.maxionGoalSectionChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Vibes Goals Chart

  dashVibeGraphData() {
    this.token = localStorage['token'];
    this.dashboardService.dashVibeDetails(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.vibesArray = res.data;
        this.vibeSectionChart(); // update the pie chart
      } else {
        this.toastr.success(res.message);
      }
    });
  }

  // Dashboard Vibe Question Chart

  dashVibeQuesGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashVibeQuestionDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.vibeQuestionArray = res.data;
          this.vibeQuesSectionChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Habit Chart

  dashHabitGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashHabitsDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.habitArray = res.data;

          for (const property in this.habitArray) {
            for (const element in this.habitArray[property]) {
              this.habitArr[element] = this.habitArray[property][element];
            }
          }
          console.log(this.habitArr, 'AAAAAAa');
          this.habitSectionChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }

  // Dashboard Preferences Chart

  dashpreferenceGraphData() {
    this.token = localStorage['token'];
    this.dashboardService
      .dashPreferenceDetails(this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.perferenceDtlsArray = res.data;
          this.createpreferenceChart(); // update the pie chart
        } else {
          this.toastr.success(res.message);
        }
      });
  }
}
