<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>Experiences
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="search-wrap">
                    <input class="form-control" placeholder="Search" type="text" (keyup)="onKeyUpEvent($event)">
                    <i class="fas fa-search"></i>
                </div>
                <div class="common_btn">
                    <a href="javascript:void(0);">
                        <button class="add-user-btn" routerLink="/experiences-add"><img src="assets/images/plus.svg"
                                alt="">Add New</button></a>
                </div>
            </div>
        </div>
    </div>

    <div class="transction-wrap">
        <div class="listingformat">
            <div class="tabledatawrap preferences-table table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Location</th>
                            <th>Description</th>
                            <th>URL</th>
                            <th>Status</th>
                            <th colspan="2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of subadminData | paginate: {id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count};let i = index;">
                            <td> <span> {{item.order}} </span> </td>
                            <td> <span class="text-eclipse"> {{item.title}} </span> </td>
                            <td><img class='table-image-width' src="{{item.image || '/assets/images/Ellipse 24@2x'}}">
                            </td>
                            <td>{{item.location}}</td>
                            <td> <span class="text-eclipse"> {{item.description}} </span> </td>
                            <td><a class="url" [href]="item.url">{{item.url}}</a></td>
                            <td [ngStyle]="{'color': (item?.isActive ? '#59bb59':'#d70d0d') }">
                                {{item?.isActive ? 'Active' : 'Draft'}}
                            </td>
                            <td>
                                <div class="action-btns" [routerLink]="['/experiences-edit', item.id]"><a
                                        href="javascript:void(0);">Edit</a></div>

                            </td>
                            <td>
                                <div class="action-btns" data-toggle="modal" data-target="#deletModal"
                                    (click)="openModal(item)"> <a href="javascript:void(0);">Delete</a></div>

                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="subadminData?.length == 0">
                        <tr>
                            <td colspan="9">No Data Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-md-12 custompagination">
                    <pagination-controls class="" auto-hide="true" id="delivered"
                        (pageChange)="onTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- HTML Ends Here  -->
</div>
<!-- Middle Content CSS End Here  -->


<!-- Delete Popup -->
<div class="modal fade bd-example-modal-sm common_modal" id="deletModal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel">
    <div class="modal-dialog animated fadeInDown">
        <div class="modal-content">
            <div class="modal-header">
                <div class="popupheding">
                    Delete
                </div> <button type="button" class="close" data-dismiss="modal">×</button>
            </div>
            <div class="modal-body">
                <div class="filtercard">
                    <p> Are you sure you want to delete this?</p>
                    <div class="common_btn">
                        <button data-dismiss="modal">Cancel</button>
                        <button (click)="deletePop()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Popup -->