import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Select2Module } from 'ng-select2-component';
import * as Sentry from '@sentry/angular-ivy';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { HeaderModule } from './shared/header/header.module';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { FaqListComponent } from './components/faq/faq-list/faq-list.component';
import { FaqAddComponent } from './components/faq/faq-add/faq-add.component';
import { FaqEditComponent } from './components/faq/faq-edit/faq-edit.component';
import { ChangePasswordComponent } from './components/setting/change-password/change-password.component';
import { PrivacyPolicyComponent } from './components/static-content/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/static-content/terms-condition/terms-condition.component';
import { AlcoholEditComponent } from './components/preferences/alcohol/alcohol-edit/alcohol-edit.component';
import { AlcoholConsumptionComponent } from './components/preferences/alcohol/alcohol-list/alcohol-list.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ToastrModule } from 'ngx-toastr';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AlcoholAddComponent } from './components/preferences/alcohol/alcohol-add/alcohol-add.component';
import { EventListComponent } from './components/event-management/event-list/event-list.component';
import { eventAddComponent } from './components/event-management/event-add/event-add.component';
import { HobbieAddComponent } from './components/preferences/Hobbie/hobbie-add/hobbie-add.component';
import { HobbieEditComponent } from './components/preferences/Hobbie/hobbie-edit/hobbie-edit.component';
import { HobbieListComponent } from './components/preferences/Hobbie/hobbie-list/hobbie-list.component';
import { FitnessEditComponent } from './components/preferences/fitness/fitness-edit/fitness-edit.component';
import { FitnessListComponent } from './components/preferences/fitness/fitness-list/fitness-list.component';
import { EventEditComponent } from './components/event-management/event-edit/event-edit.component';
import { FitnessAddComponent } from './components/preferences/fitness/fitness-add/fitness-add.component';
import { SmookerListComponent } from './components/preferences/smoker/smoker-list/smoker-list.component';
import { SmokerAddComponent } from './components/preferences/smoker/smoker-add/smoker-add.component';
import { SmookerEditComponent } from './components/preferences/smoker/smoker-edit/smoker-edit.component';
import { UsuallyWakeUpAddComponent } from './components/preferences/usualy-wake-up/usually-wake-up-add/usually-wake-up-add.component';
import { UsuallyWakeUpEditComponent } from './components/preferences/usualy-wake-up/usually-wake-up-edit/usually-wake-up-edit.component';
import { UsuallyWakeUpListComponent } from './components/preferences/usualy-wake-up/usually-wake-up-list/usually-wake-up-list.component';
import { InterestListComponent } from './components/preferences/interests-management/interest-list/interest-list.component';
import { InterestAddComponent } from './components/preferences/interests-management/interest-add/interest-add.component';
import { InterestEditComponent } from './components/preferences/interests-management/interest-edit/interest-edit.component';
import { PersonalityAddComponent } from './components/preferences/personalities-management/personalities-add/personalities-add.component';
import { PersonalityEditComponent } from './components/preferences/personalities-management/personalities-edit/personalities-edit.component';
import { PersonalityListComponent } from './components/preferences/personalities-management/personalities-list/personalities-list.component';
import { ValuesAddComponent } from './components/preferences/values-management/values-add/values-add.component';
import { ValuesEditComponent } from './components/preferences/values-management/values-edit/values-edit.component';
import { ValueListComponent } from './components/preferences/values-management/values-list/values-list.component';
import { LifeGoalsAddComponent } from './components/preferences/life-goals/life-goals-add/life-goals-add.component';
import { LifeGoalsEditComponent } from './components/preferences/life-goals/life-goals-edit/life-goals-edit.component';
import { MeetVibesListComponent } from './components/preferences/meet-vibes/meet-vibes-list/meet-vibes-list.component';
import { LifeGoalsListComponent } from './components/preferences/life-goals/life-goals-list/life-goals-list.component';
import { MaxionGoalsAddComponent } from './components/preferences/maxion-goals/maxion-goals-add/maxion-goals-add.component';
import { MaxionGoalsEditComponent } from './components/preferences/maxion-goals/maxion-goals-edit/maxion-goals-edit.component';
import { MaxionGoalsListComponent } from './components/preferences/maxion-goals/maxion-goals-list/maxion-goals-list.component';
import { MeetVibesAddComponent } from './components/preferences/meet-vibes/meet-vibes-add/meet-vibes-add.component';
import { MeetVibesEditComponent } from './components/preferences/meet-vibes/meet-vibes-edit/meet-vibes-edit.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthService } from './services/auth.service';
import { ConnectionsComponent } from './components/connections/connections.component';
import { NotificationListComponent } from './components/push-notification/notification-list/notification-list.component';
import { NotificationAddComponent } from './components/push-notification/notification-add/notification-add.component';
import { ContactUsComponent } from './components/static-content/contact-us/contact-us.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { PromoCodeListComponent } from './components/promo-code/promo-code-list/promo-code-list.component';
import { PromoCodeAddComponent } from './components/promo-code/promo-code-add/promo-code-add.component';
import { ManualMatchesComponent } from './components/manual-matches/manual-matches.component';
import { ReferralManagementComponent } from './components/referral-management/referral-management.component';
import { RegionListComponent } from './components/setting/rgt/region-list/region-list.component';
import { GroupListComponent } from './components/setting/rgt/group-list/group-list.component';
import { RolesComponent } from './components/setting/roles/roles.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PartnershipComponent } from './components/partnership/partnership.component';
import { CustomerModule } from './components/customers/customer.module';
import { ContentAddComponent } from './components/static-content/mobile/content-add/content-add.component';
import { ContentEditComponent } from './components/static-content/mobile/content-edit/content-edit.component';
import { ContentListComponent } from './components/static-content/mobile/content-list/content-list.component';
import { ExperienceListComponent } from './components/experiences/experience-list/experience-list.component';
import { ExperienceAddComponent } from './components/experiences/experience-add/experience-add.component';
import { ExperienceEditComponent } from './components/experiences/experience-edit/experience-edit.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SidebarComponent,
    UsuallyWakeUpAddComponent,
    UsuallyWakeUpListComponent,
    SmookerListComponent,
    SmokerAddComponent,
    HobbieListComponent,
    HobbieAddComponent,
    FitnessListComponent,
    FitnessAddComponent,
    FitnessEditComponent,
    HobbieEditComponent,
    SmookerEditComponent,
    UsuallyWakeUpEditComponent,
    EventListComponent,
    eventAddComponent,
    EventEditComponent,
    FaqListComponent,
    FaqAddComponent,
    FaqEditComponent,
    ChangePasswordComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    AlcoholAddComponent,
    AlcoholEditComponent,
    AlcoholConsumptionComponent,
    PersonalityListComponent,
    PersonalityAddComponent,
    PersonalityEditComponent,
    InterestListComponent,
    InterestAddComponent,
    InterestEditComponent,
    ValuesAddComponent,
    ValuesEditComponent,
    ValueListComponent,
    MeetVibesListComponent,
    MeetVibesEditComponent,
    MeetVibesAddComponent,
    MaxionGoalsListComponent,
    MaxionGoalsAddComponent,
    MaxionGoalsEditComponent,
    LifeGoalsListComponent,
    LifeGoalsAddComponent,
    LifeGoalsEditComponent,
    NotificationListComponent,
    NotificationAddComponent,
    ContactUsComponent,
    PromoCodeListComponent,
    PromoCodeAddComponent,
    ManualMatchesComponent,
    ReferralManagementComponent,
    RegionListComponent,
    GroupListComponent,
    ConnectionsComponent,
    RolesComponent,
    PaymentComponent,
    PartnershipComponent,
    ContentAddComponent,
    ContentEditComponent,
    ContentListComponent,
    ExperienceListComponent,
    ExperienceAddComponent,
    ExperienceEditComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    HeaderModule,
    NgxPaginationModule,
    ToastrModule.forRoot(), // ToastrModule added
    CKEditorModule,
    MatTabsModule,
    CanvasJSAngularChartsModule,
    Select2Module,
    CustomerModule,
  ],
  providers: [
    AuthService,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
