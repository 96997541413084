import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RejectModalComponent } from './reject-modal/reject-modal.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { Select2Module } from 'ng-select2-component';
import { ApproveModalComponent } from './approve-modal/approve-modal.component';
import { UnapproveModalComponent } from './unapprove-modal/unapprove-modal.component';

@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerDetailComponent,
    CustomerFeedbackComponent,
    RejectModalComponent,
    ApproveModalComponent,
    UnapproveModalComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    NgxPaginationModule,
    AppRoutingModule,
    Select2Module,
  ],
})
export class CustomerModule {}
