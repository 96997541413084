import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RgtService } from 'src/app/services/rgt.service';
import { PartnershipService } from 'src/app/services/partnership.service';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import * as moment from 'moment';
import 'moment-timezone';
declare var $: any;

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.css'],
})
export class PartnershipComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  editing: boolean = false;
  setAvailability: boolean = false;
  submitted: any;
  token: any;
  partnershipList: any;
  p: any = 1;
  count: any = 0;
  total: any = 10;
  err: any;
  limit: any = 10;
  offset: any;
  regionGroupList: any;
  groupTierList: any;
  wholeRegionGroupList: any;
  dataRegionList: Select2Data = [];
  dataGroupList: Select2Data = [];
  order: number = 1;
  region: any = '';
  group: any = '';
  id: any;
  name: string = '';
  title: string = '';
  location: string = '';
  discount: any = 0;
  siteUrl: string = '';
  description: string = '';
  gender: any = '';
  restrictions: any = [];
  status: any = '';
  image: any = '';
  availability: any = '';
  availabilityRegion: any = '';
  availabilityGroup: any = '';
  availabilityTier: any = '';
  availabilityType: any = '';
  dataAvailabilityList: Select2Data = [];
  dataAvailabilityTypeList: Select2Data = [
    {
      label: '',
      options: [
        {
          value: 'region',
          label: 'Region',
        },
        {
          value: 'group',
          label: 'Group',
        },
        {
          value: 'tier',
          label: 'Tier',
        },
      ],
    },
  ];
  dataRestrictionsList: Select2Data = [
    {
      label: '',
      options: [
        {
          value: 'Islamic',
          label: 'Islamic',
        },
        {
          value: 'Indian',
          label: 'Indian',
        },
        {
          value: 'Expat',
          label: 'Expat',
        },
      ],
    },
  ];
  dataGenderList: Select2Data = [
    {
      label: '',
      options: [
        {
          value: 'Male',
          label: 'Male',
        },
        {
          value: 'Female',
          label: 'Female',
        },
        {
          value: 'Both',
          label: 'Both',
        },
      ],
    },
  ];
  dataStatusList: Select2Data = [
    {
      label: '',
      options: [
        {
          value: 'ACTIVE',
          label: 'Active',
        },
        {
          value: 'INACTIVE',
          label: 'Inactive',
        },
      ],
    },
  ];

  constructor(
    public router: Router,
    private rgtService: RgtService,
    private partnershipService: PartnershipService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.partnershipListing();
    this.regionListing();
    this.wholeRegionGroupListing();
  }

  partnershipListing() {
    this.offset = this.p - 1;
    let obj = {
      limit: this.total,
      offset: this.offset,
      region_id: this.region,
      group_id: this.group,
    };
    this.token = localStorage['token'];

    this.partnershipService
      .partnershipList(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.count = res.data.total_count;
          this.partnershipList = res.data.data;
        } else if (
          res.status == 400 &&
          res.message == 'Please provide valid token'
        ) {
          this.toastr.error(res.message);
          this.router.navigate(['/login']);
          this.err = res.data;
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  regionListing() {
    this.token = localStorage['token'];

    this.rgtService.regionGroupList(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.regionGroupList = res.data.data;

        let regionList: any = [];
        res.data.data.forEach((regionItem: any) => {
          regionList.push({
            value: regionItem.id,
            label: regionItem.name,
          });
        });

        this.dataRegionList = [
          {
            label: '',
            options: regionList,
          },
        ];
      } else if (
        res.status == 400 &&
        res.message == 'Please provide valid token'
      ) {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data;
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  groupListing() {
    let obj = {
      region_id: this.region,
    };

    this.token = localStorage['token'];

    this.rgtService.groupList(obj, this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.groupTierList = res.data.data;
      } else if (
        res.status == 400 &&
        res.message == 'Please provide valid token'
      ) {
        this.toastr.error(res.message);
        this.router.navigate(['/login']);
        this.err = res.data;
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  wholeRegionGroupListing() {
    this.token = localStorage['token'];

    this.rgtService.regionGroupList(this.token).subscribe((res: any) => {
      if (res && res.status == 200) {
        this.wholeRegionGroupList = res.data.data;
      } else {
        this.toastr.success(res.message);
        alert(res.message);
      }
    });
  }

  getNewOrder() {
    // Get the last order number and increment it by 1
    const partnerShipLength = this.partnershipList?.length;
    if (partnerShipLength > 0) {
      return this.partnershipList[partnerShipLength - 1].order + 1;
    }
    return 1;
  }

  isOrderInvalid(): boolean {
    return !this.order || this.order <= 0 || isNaN(Number(this.order));
  }

  initModal() {
    this.submitted = false;
    this.editing = false;
    this.setAvailability = false;
    this.id = '';
    this.availability = '';
    this.availabilityType = '';
    this.availabilityRegion = '';
    this.availabilityGroup = '';
    this.availabilityTier = '';
    this.name = '';
    this.title = '';
    this.location = '';
    this.discount = 0;
    this.description = '';
    this.siteUrl = '';
    this.image = '';
    this.gender = '';
    this.restrictions = [];
    this.status = '';
    this.status = 'ACTIVE';
    this.order = this.getNewOrder();
  }

  openModal(id?: any, editingModal?: boolean) {
    this.initModal();

    if (id) {
      this.id = id;
    }

    if (editingModal === true) {
      this.editing = true;
      this.setAvailability = true;

      const partnership = this.partnershipList.find(
        (item: any) => item.id == id
      );

      if (partnership?.tier_id) {
        this.availabilityType = 'tier';

        this.availabilityRegion = partnership?.region_id;
        this.availabilityGroup = partnership?.group_id;
        this.availabilityTier = partnership?.tier_id;

        this.availability = partnership?.tier_id;
      } else if (partnership?.group_id) {
        this.availabilityType = 'group';

        this.availabilityRegion = partnership?.region_id;
        this.availabilityGroup = partnership?.group_id;

        this.availability = partnership?.group_id;
      } else if (partnership?.region_id) {
        this.availabilityType = 'region';

        this.availabilityRegion = partnership?.region_id;
        this.availability = partnership?.region_id;
      }

      this.name = partnership?.name;
      this.title = partnership?.title;
      this.location = partnership?.location;
      this.discount = partnership?.discount;
      this.description = partnership?.description;
      this.siteUrl = partnership?.site_url;
      this.gender = partnership?.gender;
      this.restrictions = JSON.parse(partnership?.restrictions);
      this.status = partnership?.status;
      this.order = partnership?.order;
    }
  }

  updateAvailabilityType(event: Select2UpdateEvent) {
    this.availabilityType = event.value;

    if (this.setAvailability === false) {
      this.availability = '';
    }

    if (this.availabilityType == 'region') {
      if (this.dataRegionList.length > 0) {
        this.dataAvailabilityList = this.dataRegionList;
      }
    } else if (this.availabilityType == 'group') {
      this.dataAvailabilityList = [];

      this.wholeRegionGroupList.forEach((region: any) => {
        let groupList: any[] = [];
        region.groups.forEach((group: any) => {
          groupList.push({
            value: group.id,
            label: group.name,
          });
        });

        this.dataAvailabilityList.push({
          label: region.name,
          options: groupList,
        });
      });
    } else if (this.availabilityType == 'tier') {
      this.dataAvailabilityList = [];

      this.wholeRegionGroupList.forEach((region: any) => {
        region.groups.forEach((group: any) => {
          let tierList: any[] = [];
          group.tiers.forEach((tier: any) => {
            tierList.push({
              value: tier.id,
              label: tier.name,
            });
          });

          this.dataAvailabilityList.push({
            label: `${region.name} - ${group.name}`,
            options: tierList,
          });
        });
      });
    }

    this.setAvailability = false;
  }

  updateAvailability(event: Select2UpdateEvent) {
    this.availability = event.value;
  }

  updateRestrictions(event: Select2UpdateEvent) {
    this.restrictions = event.value;
  }

  updateGender(event: Select2UpdateEvent) {
    this.gender = event.value;
  }

  updateOrder(event: any) {
    this.order = event.value;
  }

  updateStatus(event: Select2UpdateEvent) {
    this.status = event.value;
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.partnershipListing();
  }

  updateRegion(event: Select2UpdateEvent) {
    this.region = event.value;
    this.group = '';

    if (this.regionGroupList) {
      const currentRegion = this.regionGroupList.find((item: any) => {
        return item.id == event.value;
      });

      if (currentRegion) {
        let groupList = currentRegion.groups ?? [];

        groupList = groupList.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        this.dataGroupList = [
          {
            label: '',
            options: groupList,
          },
        ];

        this.groupListing();
        this.partnershipListing();
      }
    }
  }

  updateGroup(event: Select2UpdateEvent) {
    this.group = event.value;

    if (this.groupTierList) {
      this.partnershipListing();
    }
  }

  resetFileInput() {
    this.fileInput!.nativeElement.value = '';
  }

  calculateAspectRatio(width: number, height: number): number {
    return width / height;
  }

  onImageSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.image = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    this.submitted = true;

    if (
      this.availability == '' ||
      this.name == '' ||
      this.title == '' ||
      this.description == '' ||
      this.location == '' ||
      this.isOrderInvalid()
    ) {
      return;
    }

    if (this.availabilityType == 'region') {
      this.availabilityRegion = this.availability;
    } else if (this.availabilityType == 'group') {
      const group = this.wholeRegionGroupList.find((region: any) => {
        return region.groups.find((group: any) => {
          return group.id == this.availability;
        });
      });

      if (group) {
        this.availabilityGroup = this.availability;
      }
    } else if (this.availabilityType == 'tier') {
      const tier = this.wholeRegionGroupList.find((region: any) => {
        return region.groups.find((group: any) => {
          return group.tiers.find((tier: any) => {
            return tier.id == this.availability;
          });
        });
      });

      if (tier) {
        this.availabilityTier = this.availability;
      }
    }

    this.token = localStorage['token'];
    let formData = new FormData();
    formData.append('order', this.order.toString());
    formData.append('name', this.name);
    formData.append('title', this.title);
    formData.append('location', this.location);
    formData.append('discount', this.discount);
    formData.append('description', this.description);
    formData.append('site_url', this.siteUrl);
    formData.append('gender', this.gender);
    formData.append('restrictions', JSON.stringify(this.restrictions));
    formData.append('status', this.status);
    formData.append('image', this.image);

    if (this.availabilityRegion) {
      formData.append('region_id', this.availabilityRegion);
    }

    if (this.availabilityGroup) {
      formData.append('group_id', this.availabilityGroup);
    }

    if (this.availabilityTier) {
      formData.append('tier_id', this.availabilityTier);
    }

    if (this.editing === true) {
      this.partnershipService
        .updatePartnership(this.id, formData, this.token)
        .subscribe((res: any) => {
          if (res && res.status == 200) {
            this.toastr.success(res.message);
            this.partnershipListing();
            $('#upsertPartnershipModal').modal('hide');
          } else {
            this.toastr.error(res.message);
          }
        });
    } else {
      this.partnershipService
        .createPartnership(formData, this.token)
        .subscribe((res: any) => {
          if (res && res.status == 200) {
            this.toastr.success(res.message);
            this.partnershipListing();
            $('#upsertPartnershipModal').modal('hide');
          } else {
            this.toastr.error(res.message);
          }
        });
    }
  }

  onDelete() {
    this.token = localStorage['token'];

    this.partnershipService
      .deletePartnership(this.id, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletePartnershipModal').modal('hide');
          this.partnershipListing();
        } else {
          this.toastr.error(res.message);
        }
      });
  }
}
